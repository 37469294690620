.atta-top {
  height: 32px;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
.atta-top .atta-left {
    position: absolute;
    left: 0;
}
.atta-top .atta-right {
    position: absolute;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.atta-bottom {
  height: calc(100% - 42px);
}
.fileName:hover {
  color: #409eff;
  cursor: pointer;
}
.pdfs-viewer {
  height: 100%;
}
.pdfs-viewer .el-dialog__body {
    height: 100%;
    overflow: hidden;
}
