.top {
  width: 100%;
  height: 80px;
  background: #fff;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.top .logo {
    margin-left: 20px;
    width: 80px;
    height: 100%;
    text-align: center;
}
.top .logo .img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-top: 15px;
}
.top .top-left {
    width: calc(100% - 100px);
    height: 100%;
}
.top .top-left .title {
      height: 27px;
      margin-top: 15px;
      font-size: 18px;
}
.bottom {
  width: calc(100% - 40px);
  height: calc(100% - 120px);
  background: #fff;
  margin-left: 10px;
  padding: 10px;
}
